import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './adminLogin.css';

function AdminLogin() {
  const [formData, setFormData] = useState({ email: '', password: '' });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submissionMessage, setSubmissionMessage] = useState('');
  const [showOverlay, setShowOverlay] = useState(false);

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setShowOverlay(true);

    try {
      const response = await fetch('http://biletlik.net/backend/adminLogin.php', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData),
      });

      const data = await response.json();

      if (response.ok && data.success) {
        setSubmissionMessage('Admin girişi başarılı.');
        localStorage.setItem('adminEmail', formData.email);
        setTimeout(() => {
          navigate('/admin/dashboard');
        }, 2000);
      } else {
        setSubmissionMessage(data.message);
      }
      setShowOverlay(false);

    } catch (error) {
      console.error('Admin giriş hatası:', error);
      setSubmissionMessage('Bir hata oluştu.');
      setShowOverlay(false);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="login-container">
      {showOverlay && (
        <div className="overlay">
          <div className="loader"></div>
          {submissionMessage && (
            <div className="overlay-message">
              <p>{submissionMessage}</p>
            </div>
          )}
        </div>
      )}
      <div className="login-header">
        <div className="logo">biletlik admin</div>
      </div>
      <div className="login-form">
        <h2>Giriş Yap</h2>
        <form onSubmit={handleSubmit}>
          <div className="input-group">
            <label htmlFor="email">E-posta</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="input-group">
            <label htmlFor="password">Şifre</label>
            <input
              type="password"
              id="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              required
            />
          </div>
          <button type="submit" className="login-button">
            {isSubmitting ? (
              <span className="spinner"></span>
            ) : (
              'Giriş Yap'
            )}
          </button>
        </form>
        {submissionMessage && <p className="submission-message">{submissionMessage}</p>}
      </div>
    </div>
  );
}

export default AdminLogin;
