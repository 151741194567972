import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Checkout.css';

function Checkout() {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    name: '',
    address: '',
    city: '',
    postalCode: '',
    paymentMethod: 'creditCard',
  });

  const [productDetails, setProductDetails] = useState({
    image: '',
    name: '',
    price: '',
    color: '',
  });

  const [overlay, setOverlay] = useState({ show: false, message: '' });
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // Spinner için

  useEffect(() => {
    const savedProduct = JSON.parse(localStorage.getItem('selectedProduct'));
    if (savedProduct) {
      setProductDetails(savedProduct);
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setShowConfirmation(true); // Sipariş onaylama ekranını göster
  };

  const confirmOrder = async () => {
    setIsLoading(true); // Spinner'ı göster
    const userEmail = localStorage.getItem('userEmail');
    const productDetails = JSON.parse(localStorage.getItem('selectedProduct'));
  
    if (!userEmail || !productDetails || !productDetails.color) {
      setOverlay({ show: true, message: 'Lütfen önce giriş yapın ve bir ürün seçin.' });
      setIsLoading(false); // Spinner'ı gizle
      setShowConfirmation(false); // Onay ekranını kapat
      return;
    }
  
    try {
      const response = await fetch('http://biletlik.net/backend/checkout.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: userEmail,
          color: productDetails.color,
          formData: formData,
        }),
      });
  
      const data = await response.json();
      console.log("Response Data:", data);
  
      if (data.success) {
        setTimeout(() => {
          setIsLoading(false); // Spinner'ı 2 saniye sonra kapat
          navigate('/order-success'); // 2 saniye sonra yönlendirme yap
        }, 2000); // 2 saniyelik gecikme
      } else {
        setOverlay({ show: true, message: data.message || 'Siparişiniz işlenirken bir hata oluştu.' });
        setIsLoading(false); // Spinner'ı kapat
      }
  
      setTimeout(() => setOverlay({ show: false, message: '' }), 5000);
    } catch (error) {
      console.error('Hata:', error);
      setOverlay({ show: true, message: 'Siparişiniz işlenirken bir hata oluştu.' });
      setIsLoading(false); // Spinner'ı gizle
      setTimeout(() => setOverlay({ show: false, message: '' }), 5000);
    }
  
    setShowConfirmation(false); // Onay ekranını kapat
  };
  

  const cancelOrder = () => {
    setShowConfirmation(false); // Onay ekranını kapat
  };

  return (
    <div className="checkout-page">
      <header>
        <div className="logo" onClick={() => navigate('/')}>biletlik</div>
      </header>
      <main>
        <div className="product-summary">
          <img src={productDetails.image} alt={productDetails.name} />
          <div className="product-info">
            <h2>{productDetails.name}</h2>
            <p>Fiyat: {productDetails.price}</p>
            <p>Renk: {productDetails.color}</p>
          </div>
        </div>
        <h1>Teslimat Bilgileriniz</h1>
        <form onSubmit={handleSubmit}>
          <label>
            Adınız:
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </label>
          <label>
            Adres:
            <input
              type="text"
              name="address"
              value={formData.address}
              onChange={handleChange}
              required
            />
          </label>
          <label>
            Şehir:
            <input
              type="text"
              name="city"
              value={formData.city}
              onChange={handleChange}
              required
            />
          </label>
          <label>
            Posta Kodu:
            <input
              type="text"
              name="postalCode"
              value={formData.postalCode}
              onChange={handleChange}
              required
            />
          </label>
          <label>
            Ödeme Yöntemi:
            <select
              name="paymentMethod"
              value={formData.paymentMethod}
              onChange={handleChange}
            >
              <option value="creditCard">Kredi Kartı</option>
              <option value="paypal">PayPal</option>
            </select>
          </label>
          <button type="submit" className="submit-button">Siparişi Tamamla</button>
        </form>

        {showConfirmation && (
          <div className="confirmation-overlay">
            <div className="confirmation-content">
              <p>Siparişi tamamlamak istediğinize emin misiniz?</p>
              <button onClick={confirmOrder}>Onayla</button>
              <button onClick={cancelOrder}>Hayır, İptal Et</button>
            </div>
          </div>
        )}

        {isLoading && (
          <div className="spinner-overlay">
            <div className="loader"></div>
          </div>
        )}

        {overlay.show && (
          <div className="overlay">
            <div className="overlay-content">
              <p>{overlay.message}</p>
            </div>
          </div>
        )}
      </main>
    </div>
  );
}

export default Checkout;