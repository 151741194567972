import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Homepage.css';
import { FaUserCircle } from 'react-icons/fa';

function Homepage() {
  const handleLogoClick = () => {
    navigate('/');
  };

  const navigate = useNavigate();

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const userEmail = localStorage.getItem('userEmail');
    if (userEmail) {
      setIsLoggedIn(true);
    }
  }, []);

  return (
    <div className="homepage">
      <header className="homepage__header">
        <div className="homepage__logo" onClick={handleLogoClick}>biletlik</div>
        <nav className="homepage__navigation">
          <ul className="homepage__nav-list">
            <li className="homepage__nav-item">
              <a href="/aboutus" className="homepage__nav-link">Hakkımızda</a>
            </li>
            <li className="homepage__nav-item">
              <a href="/events" className="homepage__nav-link">Etkinlikler</a>
            </li>
            {isLoggedIn ? (
              <li className="homepage__nav-item">
                <a href="/profile" className="homepage__profile-icon">
                  <FaUserCircle size={24} />
                </a>
              </li>
            ) : (
              <li className="homepage__nav-item">
                <a href="/login" className="homepage__login-button">Giriş Yap</a>
              </li>
            )}
          </ul>
        </nav>
      </header>
      <main className="homepage__main">
        <div className="homepage__content">
          <h1 className="homepage__title">biletlik</h1>
          <p className="homepage__description">konserlerde seni öne geçirecek biletlik</p>
        </div>
        <a href="/product" className="homepage__buy-button">Satın Al</a>
      </main>
    </div>
  );
}

export default Homepage;
