import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Homepage from './components/Homepage';
import Login from './components/Login';
import SignUp from './components/SignUp';
import Product from './components/Product'; 
import AboutUs from './components/AboutUs';
import Profile from './components/Profile'; 
import Checkout from './components/Checkout'; 
import Events from './components/Events'; 
import AdminLogin from './components/adminLogin'
import OrderSuccess from './components/OrderSuccess';


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/product" element={<Product />} />
        <Route path="/aboutus" element={<AboutUs />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/checkout" element={<Checkout />} />
        <Route path="/events" element={<Events />} />
        <Route path="/adminLogin" element={<AdminLogin />} />
        <Route path="/order-success" element={<OrderSuccess />} />
      </Routes>
    </Router>
  );
}

export default App;
