import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Profile.css';

function Profile() {
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);
  const [biletliks, setBiletliks] = useState([]);
  const [tickets, setTickets] = useState([]);
  const userEmail = localStorage.getItem('userEmail');

  useEffect(() => {
    if (userEmail) {
      fetch(`http://biletlik.net/backend/getUser.php?email=${encodeURIComponent(userEmail)}`)
        .then(response => response.json())
        .then(data => {
          if (data.error) {
            console.error("Hata:", data.error);
          } else {
            setUserData(data.user);
            setBiletliks(data.biletliks || []);
            setTickets(data.tickets || []);
          }
        })
        .catch(error => {
          console.error("Kullanıcı bilgileri alınırken bir hata oluştu", error);
        });
    }
  }, [userEmail]);

  const handleLogoClick = () => {
    navigate('/');
  };

  const handleLogout = () => {
    localStorage.removeItem('userEmail');
    navigate('/');
  };

  if (!userData) {
    return <div>Yükleniyor...</div>; // Veriler gelene kadar yükleniyor mesajı
  }

  return (
    <div className="profile-container">
      <header>
        <div className="logo" onClick={handleLogoClick}>biletlik</div>
        <button onClick={handleLogout} className="logout-button">Çıkış Yap</button>
      </header>
      <main>
        <div className="avatar-container">
          <img src="/assets/avatar.png" alt="Avatar" className="avatar" />
        </div>
        <div className="profile-details">
          <h1>{userData.name}</h1>
          <p><strong>E-posta:</strong> {userData.email}</p>
          <p><strong>Telefon:</strong> {userData.phone}</p>
        </div>
      </main>

      {/* Biletlik ve Biletlerim bölümleri */}
      <section className="ticket-section">
        <div className="ticket-left">
          <h2>Biletlik</h2>
          {/* Biletlik fotoğraflarını dinamik olarak göster */}
          <div className="biletlik-photo">
            {biletliks.length === 0 ? (
              <p>Henüz biletlik alınmadı.</p>
            ) : (
              biletliks.map(biletlik => {
                const imageName = biletlik.color === 'blue' ? 'product1.png'
                               : biletlik.color === 'darkpurple' ? 'product2.png'
                               : biletlik.color === 'claretred' ? 'product3.png'
                               : biletlik.color === 'surfgreen' ? 'product4.png'
                               : 'default.png';
                return (
                  <img key={biletlik.id} src={`/assets/${imageName}`} alt={`Biletlik ${biletlik.id}`} />
                );
              })
            )}
          </div>
        </div>
        <div className="ticket-right">
          <h2>Biletlerim</h2>
          {/* Aldığınız biletlerin listesi */}
          <ul className="ticket-list">
            {tickets.length === 0 ? (
              <li>Henüz bilet satın alınmadı.</li>
            ) : (
              tickets.map(ticket => (
                <li key={ticket.ticket_id}>
                  {ticket.event_name} - {ticket.seat_number} - {new Date(ticket.purchase_date).toLocaleDateString()}
                </li>
              ))
            )}
          </ul>
        </div>
      </section>
    </div>
  );
}

export default Profile;