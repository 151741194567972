import React, { useState, useEffect } from 'react';
import './Events.css';
import { useNavigate } from 'react-router-dom';
import { FaUserCircle } from 'react-icons/fa';

const Events = () => {
    const [categories, setCategories] = useState({
        concert: [],
        football_match: [],
        museum: []
    });

    const [selectedEvent, setSelectedEvent] = useState(null); // Seçilen etkinliği saklamak için
    const [showConfirmation, setShowConfirmation] = useState(false); // Onaylama ekranını göstermek için
    const [showResult, setShowResult] = useState(false); // Satın alma sonucu ekranını göstermek için
    const [loading, setLoading] = useState(false); // Spinner durumunu kontrol etmek için
    const [errorMessage, setErrorMessage] = useState(null); // Hata mesajı için

    const navigate = useNavigate();
    const userEmail = localStorage.getItem('userEmail');
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    useEffect(() => {
        if (userEmail) {
            setIsLoggedIn(true);
        }
    }, [userEmail]);

    useEffect(() => {
        fetch('http://biletlik.net/backend/get_events.php')
            .then(response => response.json())
            .then(data => {
                const categorizedEvents = {
                    concert: [],
                    football_match: [],
                    museum: []
                };

                data.forEach(event => {
                    categorizedEvents[event.event_type].push(event);
                });

                setCategories(categorizedEvents);
            })
            .catch(error => console.error('Hata:', error));
    }, []);

    const handleLogoClick = () => {
        navigate('/');
    };

    const handleBuyClick = (event) => {
        if (!userEmail) {
            alert('Lütfen giriş yapın.');
            return;
        }
        setSelectedEvent(event); // Seçilen etkinliği ayarla
        setShowConfirmation(true); // Onaylama ekranını göster
    };

    const confirmPurchase = async () => {
        if (!selectedEvent) return;

        setLoading(true); // Spinner'ı göster
        setErrorMessage(null); // Hata mesajını sıfırla

        try {
            const response = await fetch('http://biletlik.net/backend/buy_ticket.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    event_id: selectedEvent.event_id,
                    user_email: userEmail
                })
            });

            const result = await response.json();
            if (result.success) {
                setShowResult(true);
            } else {
                setErrorMessage(result.error); // Hata mesajını ekranda göster
            }
        } catch (error) {
            setErrorMessage('Bir hata oluştu, lütfen tekrar deneyin.');
        } finally {
            setLoading(false); // Spinner'ı gizle
            setShowConfirmation(false); // Onaylama ekranını kapat
        }
    };

    const cancelPurchase = () => {
        setShowConfirmation(false); // Onaylama ekranını kapat
    };

    return (
        <div className="events-page">
            <header>
                <div className="logo" onClick={handleLogoClick}>biletlik</div>
                <nav>
                    <ul>
                        {isLoggedIn ? (
                            <li><a href="/profile" className="profile-icon"><FaUserCircle size={24} /></a></li>
                        ) : (
                            <li><a href="/login">Giriş Yap</a></li>
                        )}
                    </ul>
                </nav>
            </header>
            <main>
                <div className="events-list">
                    {Object.keys(categories).map(category => (
                        <div key={category} className="event-category">
                            <h2>
                                {category === 'concert' ? 'Konserler' :
                                    category === 'football_match' ? 'Futbol Maçları' :
                                        'Müzeler'}
                            </h2>
                            {categories[category].length > 0 ? (
                                categories[category].map(event => {
                                    const ticketPrice = parseFloat(event.ticket_price);
                                    const imageUrl = event.image
                                        ? `http://biletlik.net/assets/images/${event.image}`
                                        : 'http://biletlik.net/assets/images/default-image.png';

                                    return (
                                        <div key={event.event_id} className="event-item">
                                            <img src={imageUrl} alt={event.event_name} onError={(e) => { e.target.src = 'http://biletlik.net/assets/images/default-image.png'; }} />
                                            <div className="event-details">
                                                <h3>{event.event_name}</h3>
                                                <p>{event.event_date}</p>
                                                <p>{event.location}</p>
                                                <p>{event.description}</p>
                                                <p>₺{ticketPrice.toFixed(2)}</p>
                                                <button onClick={() => handleBuyClick(event)}>Satın Al</button>
                                            </div>
                                        </div>
                                    );
                                })
                            ) : (
                                <p>Bu kategoriye ait etkinlik bulunmamaktadır.</p>
                            )}
                        </div>
                    ))}
                </div>
            </main>

            {showConfirmation && (
                <div className="confirmation-overlay">
                    <div className="confirmation-content">
                        <p>Bu etkinliğe bilet almak istediğinizden emin misiniz?</p>
                        <button onClick={confirmPurchase}>Onayla</button>
                        <button onClick={cancelPurchase}>Hayır, İptal Et</button>
                    </div>
                </div>
            )}

            {loading && (
                <div className="spinner-overlay">
                    <div className="spinner"></div>
                </div>
            )}

            {showResult && (
                <div className="purchase-result-overlay">
                    <div className="purchase-result">
                        <img src={selectedEvent ? `http://biletlik.net/assets/images/${selectedEvent.image}` : 'http://biletlik.net/assets/images/default-image.png'} alt={selectedEvent ? selectedEvent.event_name : 'Etkinlik'} />
                        <h2>Bilet Satın Alma İşlemi Başarıyla Gerçekleşti</h2>
                        <p>Etkinlik: {selectedEvent ? selectedEvent.event_name : 'Bilinmiyor'}</p>
                        <p>Tarih: {selectedEvent ? selectedEvent.event_date : 'Bilinmiyor'}</p>
                        <p>Yer: {selectedEvent ? selectedEvent.location : 'Bilinmiyor'}</p>
                        <button onClick={() => setShowResult(false)}>Tamam</button>
                    </div>
                </div>
            )}

            {errorMessage && (
                <div className="error-message">
                    <p>{errorMessage}</p>
                    <button onClick={() => setErrorMessage(null)}>Tamam</button>
                </div>
            )}
        </div>
    );
};

export default Events;