import React from 'react';
import { useNavigate } from 'react-router-dom';
import './AboutUs.css';

function AboutUs() {
  const navigate = useNavigate();

  const handleLogoClick = () => {
    navigate('/');
  };

  return (
    <div className="about-us">
      <header className="about-us__header">
        <div className="about-us__logo" onClick={handleLogoClick}>biletlik</div>
        <nav className="about-us__nav">
          <ul className="about-us__nav-list">
            <li className="about-us__nav-item"><a href="/login" className="about-us__login-button">Giriş Yap</a></li>
          </ul>
        </nav>
      </header>
      <main className="about-us__main">
        <div className="about-us__content">
          <div className="about-us__text-section">
            <h1 className="about-us__title">Hakkımızda</h1>
            <p className="about-us__description">
              Biletlik, konser deneyiminizi en üst seviyeye çıkarmak için tasarlanmış yenilikçi bir platformdur.
              Misyonumuz, kullanıcılarımıza en iyi hizmeti sunarak konserlerde öne geçmelerini sağlamaktır.
            </p>
            <p className="about-us__description">
              Ekibimiz, teknoloji ve eğlence sektöründeki deneyimleri ile biletlik'i sizin için oluşturdu. 
              Bizimle iletişime geçmek ve daha fazla bilgi almak için <a href="/contact" className="about-us__contact-link">iletişim</a> sayfamızı ziyaret edebilirsiniz.
            </p>
          </div>
          <div className="about-us__image-section">
            <img src="/assets/AboutUs.png" alt="About Us" />
          </div>
        </div>
      </main>
    </div>
  );
}

export default AboutUs;
