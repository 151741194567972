import React from 'react';
import { useNavigate } from 'react-router-dom';
import './OrderSuccess.css';
import { FaUserCircle } from 'react-icons/fa';

function OrderSuccess() {
  const navigate = useNavigate();

  return (
    <div className="order-success-page">
      <header className="header">
        <div className="logo" onClick={() => navigate('/')}>biletlik</div>
        <nav>
          <ul>
          <li><a href="/profile" className="profile-icon"><FaUserCircle size={24} /></a></li>
          </ul>
        </nav>
      </header>
      <div className="success-message">
        <h1>Siparişiniz başarıyla oluşturulmuştur!</h1>
        <p>Dilerseniz ilk etkinlik biletlerinizi hemen alabilirsiniz.</p>
        <button onClick={() => navigate('/events')}>Etkinlik Biletleri Al</button>
      </div>
    </div>
  );
}

export default OrderSuccess;
